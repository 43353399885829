import { getContentfulImageUrl } from 'lib/image/getImageUrl';
import Image, { ImageProps } from 'next/image';
import { SerializedComponentItem } from 'types/contentful/SerializedComponentItem';

// Next 10.1.2 types fails combining layout, width and height together, even using layout=fixed
// see more in: https://nextjs.org/blog/next-10-1#nextimage-improvements
type NextImageSupportedProps = Partial<Omit<ImageProps, 'layout'>>;

export type AssetProps = NextImageSupportedProps & {
  entry: SerializedComponentItem;
  layout?: string;
  rawImgTag?: boolean;
  index?: number;
  ariaHidden?: boolean;
};

export const assetLoader = ({ src }) => getContentfulImageUrl({ src });

const NextImage = Image as (props: NextImageSupportedProps) => JSX.Element;

export const Asset = ({ entry, rawImgTag, index = 0, ariaHidden = true, ...rest }: AssetProps) => {
  let dimensions;

  if (!entry.assets || entry.assets.length === 0) {
    return <span>No image found.</span>;
  }

  const assetItem = entry.assets[index];

  if (rest?.layout !== 'fill') {
    dimensions = {
      width: assetItem.details.image.width,
      height: assetItem.details.image.height
    };
  }

  if (!rawImgTag) {
    return (
      <NextImage
        loader={assetLoader}
        src={assetItem.url}
        alt={ariaHidden ? '' : entry.primaryText}
        aria-hidden={ariaHidden}
        {...dimensions}
        {...rest}
      />
    );
  }

  return (
    <img
      src={assetLoader({ src: assetItem.url })}
      aria-hidden={ariaHidden}
      alt={ariaHidden ? '' : entry.primaryText}
      {...dimensions}
      {...rest}
    />
  );
};
